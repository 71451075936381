import React, {Component} from 'react';
import {CompanySettings, GeoLocation, Message} from "./models";
import {postAPI} from "./api";

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
// If you want to use the provided css
import 'react-google-places-autocomplete/dist/assets/index.css';
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {Typeahead} from "react-bootstrap-typeahead";
import { organisationSettingsServiceApi} from "./api";
import {Telemetryadminv1PaymentGateway} from "./telemetry/vending_machine/v1/typescript-axios";

const geoCoder = new window.google.maps.Geocoder();

interface Props extends IGlobalDataContext{
    settings: CompanySettings

    reloadSettings(): void

    timezones: Array<string>
}

interface State {
    admins: string
    name: string
    abn: string | null
    gst: boolean
    gst_type: string

    defaultStartAddress: string | null
    defaultStartLocation: GeoLocation

    defaultFinishAddress: string | null
    defaultFinishLocation: GeoLocation

    address:string | null
    location:GeoLocation

    sqsDexFile:boolean
    sqsUrl:string | null
    // 0: Wrong URL 1: Correct URL 2: URL haven't been verify
    sqsUrlVerified: number

    saving: boolean
    blockFailedCoil: boolean

    new_timezone: string
    product_editable_from_vending_machine: boolean
    skip_automatic_refund: boolean
    xendit_secret_key:string
    midtrans_server_key:string
    midtrans_client_key:string
    qrcode_payment_gateway:string
}

class _CompanySettingsPanel extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            defaultFinishLocation: {
                address : this.props.settings.defaultFinishLocation.address,
                longitude: this.props.settings.defaultFinishLocation.longitude,
                latitude: this.props.settings.defaultFinishLocation.latitude
            },
            defaultStartLocation: {
                address : this.props.settings.defaultStartLocation.address,
                longitude: this.props.settings.defaultStartLocation.longitude,
                latitude: this.props.settings.defaultStartLocation.latitude
            },
            location:{
                address: this.props.settings.location.address,
                longitude:this.props.settings.location.longitude,
                latitude:this.props.settings.location.latitude
            },

            name: this.props.settings.name,
            abn: this.props.settings.abn,
            gst: this.props.settings.gst,
            admins: this.props.settings.admins.join(', '),
            gst_type: this.props.settings.gst_type,

            defaultStartAddress: this.props.settings.defaultStartLocation.address,
            defaultFinishAddress: this.props.settings.defaultFinishLocation.address,
            address:this.props.settings.location.address,

            sqsDexFile:this.props.settings.sqsDexFile,
            sqsUrl:this.props.settings.sqsUrl,
            sqsUrlVerified:1,

            saving: false,
            blockFailedCoil: this.props.settings.blockFailedCoil,

            new_timezone: this.props.settings.timezone,
            product_editable_from_vending_machine: false,
            skip_automatic_refund: false,
            xendit_secret_key:"",
            midtrans_server_key:"",
            midtrans_client_key:"",
            qrcode_payment_gateway:Telemetryadminv1PaymentGateway.Unspecified
        }
        this.loadOrganisationSettings()
    }

    loadOrganisationSettings(){
        new organisationSettingsServiceApi().organisationSettingsServiceGetOrganisationSettings().then(res =>{
            this.setState({
                product_editable_from_vending_machine: !!res.data.productEditableFromVendingMachine,
                skip_automatic_refund: !!res.data.skipAutomaticRefund,
                xendit_secret_key:res.data.xenditSecretKey as string,
                midtrans_server_key:res.data.maskedMidtransServerKey as string,
                midtrans_client_key:res.data.maskedMidtransClientKey as string,
                qrcode_payment_gateway:res.data.qrcodePaymentGateway as string
            })

        }).catch(e =>{
            alert("get organisation error "+e)
        })
    }

    updateOrganisationSettings(){
        new organisationSettingsServiceApi().organisationSettingsServiceUpdateOrganisationSettings({
            productEditableFromVendingMachine: this.state.product_editable_from_vending_machine,
            skipAutomaticRefund: this.state.skip_automatic_refund,
            xenditSecretKey:this.state.xendit_secret_key,
            midtransServerKey:this.state.midtrans_server_key,
            midtransClientKey:this.state.midtrans_client_key,
            qrcodePaymentGateway:this.state.qrcode_payment_gateway as Telemetryadminv1PaymentGateway,
        }).then(res => {
            console.log("update organisation setting success")
            this.props.pushFlashMessage(new Message("Saved", "success"));
        }).catch(e => {
            alert("update organisation error "+e)
        })
    }

    handleNameChange(event) {
        this.setState({name: event.target.value})
    }

    handleGstTypeChange(event) {
        console.log("new gst type:" + event.target.value);
        this.setState({gst_type: event.target.value})
    }

    handleDefaultFinishAddressSelection(selection) {
        console.log(selection);
        this.setState({defaultFinishAddress: selection.description});

        geoCoder.geocode({address: selection.description}, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
                this.setState({
                    defaultFinishLocation: {
                        address: selection.description,
                        latitude: results[0].geometry.location.lat(),
                        longitude: results[0].geometry.location.lng()
                    }
                })
            }
        });
    }

    handleDefaultStartAddressChange(selection) {
        this.setState({defaultStartAddress: selection.description});

        geoCoder.geocode({address: selection.description}, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
                this.setState({
                    defaultStartLocation: {
                        address: selection.description,
                        latitude: results[0].geometry.location.lat(),
                        longitude: results[0].geometry.location.lng()
                    }
                })
            }
        });
    }

    handleAddressChange(selection) {
        this.setState({address: selection.description});

        geoCoder.geocode({address: selection.description}, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
                this.setState({
                    location: {
                        address: selection.description,
                        latitude: results[0].geometry.location.lat(),
                        longitude: results[0].geometry.location.lng()
                    }
                })
            }
        });
    }

    changeBlockCoil(e) {
        if (e.target.value === "yes") {
            this.setState({blockFailedCoil: true})
        } else {
            this.setState({blockFailedCoil: false})
        }
        e.preventDefault();
        e.stopPropagation();
    }

    handleSave(event) {
        event.preventDefault();
        event.stopPropagation();

        this.updateOrganisationSettings()

        if (!this.needSave()) {
            return false;
        }
        if (this.state.sqsUrlVerified !== 1){
            window.alert("Please test SQS URL first")
            return false
        }
        if (this.state.sqsDexFile && this.state.sqsUrl === null){
            window.alert("SQS URL is required")
            return false
        }
        console.log("Saving...");

        let newSettings = {...this.props.settings};
        newSettings.name = this.state.name;
        newSettings.abn = this.state.abn;
        newSettings.gst = this.state.gst;
        newSettings.gst_type = this.state.gst_type;

        // newSettings.defaultFinishAddress = this.state.defaultFinishAddress;
        // newSettings.defaultStartAddress = this.state.defaultStartAddress;
        // newSettings.address = this.state.address;

        newSettings.defaultStartLocation = this.state.defaultStartLocation;
        newSettings.defaultFinishLocation = this.state.defaultFinishLocation;
        newSettings.location = this.state.location;

        newSettings.admins = this.state.admins.split(",").map((email) => email.trim());

        newSettings.sqsDexFile = this.state.sqsDexFile;
        newSettings.sqsUrl = this.state.sqsUrl;
        newSettings.blockFailedCoil = this.state.blockFailedCoil;
        newSettings.timezone = this.state.new_timezone;
        console.log(newSettings);

        this.setState({saving: true});
        postAPI(`/api/companies/${this.props.settings.uuid}/settings`, newSettings).then((response) => {
            console.log(response);
            this.setState({saving: false});

            if (response.data.success) {
                this.props.pushFlashMessage(new Message("Saved", "success"));
                this.props.reloadSettings();
            } else {
                window.alert(response.data.message);
            }
        })

    }

    changeSqsDexFile(e) {
        if (e.target.value === "yes") {
            if (this.state.sqsUrl === null){
                this.setState({sqsDexFile:true, sqsUrlVerified:2})
            } else {
                this.setState({sqsDexFile:true, sqsUrlVerified:1})
            }
        } else {
            if (this.state.sqsUrl === null){
                this.setState({sqsDexFile:false, sqsUrlVerified:1})
            } else {
                this.setState({sqsDexFile:false})
            }
        }
        e.preventDefault();
        e.stopPropagation();
    }

    handleCompanySqsUrlChanges(e) {
        if (e.target.value === "") {
            if (this.state.sqsDexFile){
                this.setState({sqsUrl:null, sqsUrlVerified:2})
            } else {
                this.setState({sqsUrl:null, sqsUrlVerified:1})
            }
        } else {
            if (e.target.value !== this.props.settings.sqsUrl){
                this.setState({sqsUrl:e.target.value, sqsUrlVerified:2})
            } else {
                this.setState({sqsUrl:e.target.value, sqsUrlVerified:1})
            }
        }
        e.preventDefault();
        e.stopPropagation();
    }

    verifySqsUrl(e){
        e.preventDefault();
        e.stopPropagation();
        if (this.state.sqsUrl !== null){
            postAPI(`/api/sqs/url_verification`, {sqsUrl:this.state.sqsUrl}).then((response) => {
                if (response.data.success) {
                    this.setState({sqsUrlVerified:response.data.urlVerified ? 1: 0})
                }
            })
        } else {
            this.setState({sqsUrlVerified:1})
        }
    }

    handleGstChange(event) {
        console.log(event.target.checked);
        this.setState({gst: event.target.checked})
    }

    needSave(): boolean {
        if (this.state.name !== this.props.settings.name) {
            return true;
        }

        if (this.state.gst_type !== this.props.settings.gst_type) {
            return true;
        }

        if (this.state.gst !== this.props.settings.gst) {
            return true;
        }

        if (this.state.abn !== this.props.settings.abn) {
            return true;
        }

        if (this.state.admins !== this.props.settings.admins.join(', ')) {
            return true;
        }

        if (this.state.defaultStartAddress !== this.props.settings.defaultStartLocation.address) {
            return true;
        }

        if (this.state.defaultFinishAddress !== this.props.settings.defaultFinishLocation.address) {
            return true;
        }

        if (this.state.address !== this.props.settings.location.address){
            return true;
        }

        if (this.state.sqsDexFile !== this.props.settings.sqsDexFile){
            return true
        }

        if (this.state.sqsUrl !== this.props.settings.sqsUrl){
            return true
        }

        if (this.state.blockFailedCoil !== this.props.settings.blockFailedCoil){
            return true
        }

        if (this.state.new_timezone !== this.props.settings.timezone){
            return true
        }

        return false;
    }

    handleAbnChange(event): void {
        this.setState({abn: event.target.value})
    }

    handleTimezoneChange(selectedTimezone) {
        this.setState({new_timezone: selectedTimezone[0]}, ()=>{console.log("timezone", this.state.new_timezone)});
    }

    handleProductEditChange(e){
        if (e.target.value === "yes") {
            this.setState({product_editable_from_vending_machine:true})
        } else {
            this.setState({product_editable_from_vending_machine:false})
        }
    }

    handleSkipAutomaticRefundChange(e) {
        if (e.target.value === "yes") {
            this.setState({ skip_automatic_refund: true })
        } else {
            this.setState({ skip_automatic_refund: false })
        }
    }

    handleQrcodePaymentGatewayChange(e){
       this.setState({qrcode_payment_gateway:e.target.value})
    }

    handleXenditSecretKeyChange(e){
        this.setState({xendit_secret_key:e.target.value})
    }

    handleMidtransServerKeyChange(e){
        this.setState({midtrans_server_key:e.target.value})
    }

    handleMidtransClientKeyChange(e){
        this.setState({midtrans_client_key:e.target.value})
    }


    render() {
        return (
            <form autoComplete="off" onSubmit={this.handleSave.bind(this)} className="form">
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Business Name </label>
                            <input className="form-control" type="text" value={this.state.name}
                                   onChange={this.handleNameChange.bind(this)}/>
                        </div>

                        <div className="form-group">
                            <label>ABN (Australia Business Number)</label>
                            <input className="form-control" type="text" value={this.state.abn !== null ? this.state.abn : ""}
                                   onChange={this.handleAbnChange.bind(this)}/>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>GST Registered?</label>
                                    <br/>
                                    <input type="checkbox" checked={this.state.gst}
                                           onChange={this.handleGstChange.bind(this)}/> Yes
                                </div>
                            </div>
                            {this.state.gst &&
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Pricing including GST?</label>
                                    <select className="form-control" onChange={this.handleGstTypeChange.bind(this)}
                                            value={this.state.gst_type}>
                                        <option value="inclusive">Inclusive (Stick price includes GST)</option>
                                        <option value="plus">Plus GST (Stick price does not include GST)</option>
                                    </select>
                                </div>
                            </div>
                            }
                        </div>

                        <div className={"form-group"}>
                            <label>Send DEX File Through SQS</label>
                            <select className={"form-control"}
                                    onChange={this.changeSqsDexFile.bind(this)}
                                    value={this.state.sqsDexFile ? "yes" : "no"}>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                        </div>

                        <div className={"form-group"}>
                            <label>Block Purchase From A Coil Reports Vend Failure</label>
                            <select className={"form-control"}
                                    onChange={this.changeBlockCoil.bind(this)}
                                    value={this.state.blockFailedCoil? "yes" : "no"}>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                        </div>

                        <div className={"form-group"}>
                            <label>SQS URL</label>
                            <div className={"row"}>
                                <div className={"col-xs-10"}>
                                    <div>
                                        <input className="form-control" type="text" value={this.state.sqsUrl ? this.state.sqsUrl : ""}
                                               onChange={this.handleCompanySqsUrlChanges.bind(this)}/>
                                    </div>
                                </div>
                                <div className={"col-xs-2"}>
                                    <div className={"row"}>
                                        <div className={"col-xs-6"}>
                                            <button className="btn btn-success" onClick={this.verifySqsUrl.bind(this)}>Test</button>
                                        </div>
                                        {this.state.sqsUrlVerified === 1 && <div className={"col-xs-6"}>
                                            <i className="fa fa-check" style={{color: "green", verticalAlign:"middle"}} aria-hidden="true"/>
                                        </div>}
                                        {this.state.sqsUrlVerified === 0 && <div className={"col-xs-6"}>
                                            <i className="fa fa-exclamation" style={{color: "red", verticalAlign:"middle"}} aria-hidden="true"/>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"form-group"}>
                            <label>Midtrans Server Key</label>
                            <input className="form-control" type="text" value={this.state.midtrans_server_key} onChange={this.handleMidtransServerKeyChange.bind(this)} placeholder={"input midtrans server key"}/>
                        </div>

                        <div className={"form-group"}>
                            <label>Midtrans Client Key</label>
                            <input className="form-control" type="text" value={this.state.midtrans_client_key} onChange={this.handleMidtransClientKeyChange.bind(this)} placeholder={"input midtrans client key"}/>
                        </div>

                        <input type="submit" value="Save" className="btn btn-success btn-lg"/>

                        <br/>

                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Default Start Address</label>
                            <GooglePlacesAutocomplete
                                key={1} idPrefix={'1'}
                                onSelect={this.handleDefaultStartAddressChange.bind(this)}
                                initialValue={this.state.defaultStartAddress !== null ? this.state.defaultStartAddress : ""}
                                autocompleteRequest={{
                                    componentRestrictions: {
                                        country: ['au']
                                    }
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label>Default Finish Address</label>
                            <GooglePlacesAutocomplete
                                key={2} idPrefix={'2'}
                                onSelect={this.handleDefaultFinishAddressSelection.bind(this)}
                                initialValue={this.state.defaultFinishAddress !== null ? this.state.defaultFinishAddress : ""}
                                autocompleteRequest={{
                                    componentRestrictions: {
                                        country: ['au']
                                    }
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label>Business Address</label>
                            <GooglePlacesAutocomplete
                                key={3} idPrefix={'3'}
                                onSelect={this.handleAddressChange.bind(this)}
                                initialValue={this.state.address !== null ? this.state.address : ""}
                                autocompleteRequest={{
                                    componentRestrictions: {
                                        country: ['au']
                                    }
                                }}
                            />
                        </div>

                        <div className="form-group">
                            <label>Specify timezone: </label>
                            <Typeahead onChange={this.handleTimezoneChange.bind(this)}
                                       defaultSelected={[this.props.settings.timezone]}
                                       id={"specify timezone"}
                                       options={this.props.timezones}/>
                        </div>


                        <div className="form-group">
                            <label>Product Editable From Vending Machine: </label>
                            <div>
                                <select className={"form-control"}
                                        onChange={this.handleProductEditChange.bind(this)}
                                        value={this.state.product_editable_from_vending_machine ? "yes" : "no"}>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                            </div>
                        </div>

                        <div className={"form-group"}>
                            <label>Xendit Secret Key</label>
                            <input className="form-control" type="text" value={this.state.xendit_secret_key} onChange={this.handleXenditSecretKeyChange.bind(this)} placeholder={"input xendit secret key"}/>
                        </div>

                        <div className="form-group">
                            <label>QRCode Payment Gateway: </label>
                            <div>
                                <select className={"form-control"}
                                        onChange={this.handleQrcodePaymentGatewayChange.bind(this)}
                                        value={this.state.qrcode_payment_gateway}>
                                    <option value={Telemetryadminv1PaymentGateway.Xendit}>Xendit</option>
                                    <option value={Telemetryadminv1PaymentGateway.Midtrans}>Midtrans</option>
                                </select>
                            </div>
                        </div>

                        <div className="form-group">
                            <label>Skip Automatic Refund: </label>
                            <div>
                                <select className={"form-control"}
                                    onChange={this.handleSkipAutomaticRefundChange.bind(this)}
                                    value={this.state.skip_automatic_refund ? "yes" : "no"}>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        );
    }
}

// export default CompanySettingsPanel

export const CompanySettingsPanel = withGlobalData(_CompanySettingsPanel);