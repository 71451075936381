import React, {Component} from 'react';

import {Highlighter, Typeahead} from 'react-bootstrap-typeahead';

import {withAuthentication} from "./authentication";
import {getAPI, postAPI} from "./api";
import {AddProductPanel} from './products/add_product_panel';
import {Link, Route, Switch, withRouter} from "react-router-dom";
import {Product, ProductFilter} from "./models";


import DataTable from 'react-data-table-component';
import Modal from 'react-modal';
import {ProductStatsComponent} from "./product_stats";
import {Default, Mobile} from "./components/responsive";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import InlineProductPhoto from "./inline_product_photo";
import {Helmet} from "react-helmet";

interface State {
    products: Array<Product>
    currentEditingProduct: Product | null
    mergeModalOpen: boolean
    mergeDestination: Array<Product>
    toggledClearRows: boolean
    disableStandardProductImporting: boolean;
    productFilter: ProductFilter

    categories: Array<String>
    typesList: Array<String>
    unitsList: Array<String>
    ingredientsList: Array<Product>
}

interface Props extends IGlobalDataContext {
    history: any
    isMobile: boolean
}

const ProductStatsExp = (data) => {
    const product: Product = data.data;

    return (
        <ProductStatsComponent product={product}/>
    );
};

class _Products extends Component<Props, State> {
    private selectedRows: Product[];

    importProducts(e) {
        e.preventDefault();
        e.stopPropagation();

        if (window.confirm("Are you sure to import VoT's standard product database again?")) {
            postAPI("/api/products/import", {}).then((response) => {
                if (response.data.success) {
                    window.alert("Product database imported successfully.");
                    this.props.reloadAllProducts();
                } else {
                    window.alert(response.data.message);
                }
            })
        }

        return false;
    }

    constructor(props) {
        super(props);

        this.state = {
            categories: [],
            ingredientsList: [],
            typesList: [],
            unitsList: [],

            products: this.props.allProducts,
            mergeModalOpen: false,
            mergeDestination: [],
            currentEditingProduct: null,
            toggledClearRows: false,
            disableStandardProductImporting: true,
            productFilter: ProductFilter.ALL


        };

        this.selectedRows = [];
    }

    loadCompanyDetail() {
        getAPI("/api/companies/mine/disable_standard_product_importing").then((response) => {
            if (response.data.success) {
                this.setState({disableStandardProductImporting: response.data.disableStandardProductImporting})
            }
        })
    }

    componentDidMount() {
        this.props.reloadAllProducts();
        this.loadCompanyDetail();

        getAPI(`/api/categories`).then((response) => {
            if (response.data.success) {
                this.setState({categories: response.data.productCategories});
            } else {
                alert(response.data.message);
            }
        })

        getAPI(`/api/products/all_type`).then((response) => {
            if (response.data.success) {
                this.setState({typesList: response.data.types});
            } else {
                alert(response.data.message);
            }
        })

        getAPI(`/api/products/all_unit`).then((response) => {
            if (response.data.success) {
                this.setState({unitsList: response.data.units});
            } else {
                alert(response.data.message);
            }
        })

        this.setState({
            ingredientsList: this.props.allProducts.filter((product) => {
                return product.productType === 'INGREDIENT'
            })
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.allProducts !== state.products) {
            return {
                products: props.allProducts
            };
        }
        return null;
    }

    handleAdd(name, shelftimeNotice, shelftimeAlert, pictureEncodedContent, categoryId, productType, productUnit, formulaList, nutrition, displayPriority) {
        postAPI("/api/products", {
            name: name,
            shelftimeNotice: shelftimeNotice,
            shelftimeAlert: shelftimeAlert,
            categoryId: categoryId,
            productType: productType,
            productUnit: productUnit,
            formulaList: formulaList,
            nutrition: nutrition,
            displayPriority: displayPriority
        }).then((result) => {
            if (result.data.success) {
                if (pictureEncodedContent) {
                    const id = result.data.product.id
                    postAPI(`/api/products/${id}/picture`, {imageContent: pictureEncodedContent}).then((response) => {
                        if (response.data.success) {
                            this.props.history.push('/products');
                            this.props.reloadAllProducts();
                        } else {
                            alert(response.data.message);
                        }
                    })
                } else {
                    this.props.history.push('/products');
                    this.props.reloadAllProducts();
                }
            } else {
                alert(result.data.message);
            }
        });
    }

    handleTableUpdate(state) {
        this.selectedRows = state.selectedRows;
    }

    handleRemove(productID) {
        if (window.confirm(`Delete ${productID}?`)) {
            postAPI(`/api/products/${productID}/delete`, {}).then((result) => {
                if (result.data.success) {
                    this.props.reloadAllProducts()
                } else {
                    alert(result.data.message);
                }
            });
        }
    }

    cancel(event) {
        event.stopPropagation();
        event.preventDefault();

        this.setState({mergeModalOpen: false});

        return false;
    }

    handleMerge(event) {
        event.preventDefault();

        this.setState({mergeModalOpen: true});

        return false;
    }

    handleMergeDestination(selectedOption) {
        this.setState({
            mergeDestination: selectedOption
        })
    }

    handleMergeForm(event) {
        event.preventDefault();

        const products = this.selectedRows.map((product) => {
            return product.id
        });

        if (this.state.mergeDestination.length > 0) {
            const destination = this.state.mergeDestination[0];
            postAPI(`/api/products/merges`, {
                products: products,
                destination: destination.id
            }).then((response) => {
                console.log(response);

                if (response.data.success) {
                    this.props.reloadAllProducts();
                    this.setState({
                        mergeModalOpen: false,
                        toggledClearRows: !this.state.toggledClearRows
                    });
                } else {
                    window.alert(response.data.message);
                }
            });
        } else {
            window.alert("Please select a merge destination.");
        }

        return false;
    }

    handleSingleMerge(productId) {
        const product = this.props.findProduct(productId);
        if (product == null) {
            window.alert("Failed to merge product. Please refresh page");
            return;
        }

        const openModal = () => {
            this.selectedRows = [product];
            this.setState({mergeModalOpen: true});
        };

        if (this.selectedRows.length > 0) {
            this.setState(
                {toggledClearRows: !this.state.toggledClearRows},
                openModal
            );
        } else {
            openModal();
        }

    }

    handleProductFilter(event) {
        event.preventDefault();
        this.setState({productFilter: ProductFilter[event.target.value]})
    }

    render() {
        const cancel = this.cancel.bind(this);
        let products;
        if (this.state.productFilter === ProductFilter.NORMAL) {
            products = this.state.products.filter((product) => {
                return !product.placeholder
            })
        } else if (this.state.productFilter === ProductFilter.PLACEHOLDER) {
            products = this.state.products.filter((product) => {
                return product.placeholder
            })
        } else {
            products = this.state.products
        }

        const contextActions = [
            <button onClick={this.handleMerge.bind(this)} className={"btn btn-success btn-sm"} key={"merge"}>
                Merge
            </button>
        ];

        const longColumns = [
            {
                name: "ID",
                selector: "id",
                sortable: true,
                ignoreRowClick: true,
                width: "150px",
                cell: (product: Product) => {
                    return (
                        <div>
                            {product.id}

                            &nbsp;
                            <Link to={"/products/" + product.id + "/settings"}>Details...</Link>
                        </div>
                    )
                }
            },
            {
                name: "Name",
                selector: "name",
                sortable: true,
                cell: (product: Product) => {
                    return (
                        <div>
                            {product.imageUploadKey ? <><InlineProductPhoto product={product}/>&nbsp;</> : null}
                            {product.source !== "VOT" ? product.name + " (" + product.source + ")" : product.name}
                            &nbsp;
                            {product.placeholder ? <span
                                title="Placeholder product is created by system automatically. You can update product name to remove this label."
                                className="label label-warning"><i
                                className="fa fa-warning">&nbsp;</i> placeholder</span> : null}
                        </div>
                    )
                }
            },
            {
                name: "Shelftime",
                sortable: false,
                ignoreRowClick: true,
                cell: (product: Product) => {
                    return (<div>
                        Notice after {product.shelftimeNotice || '-'} days, alert
                        after {product.shelftimeAlert || '-'} days
                    </div>)
                }

            },
            {
                name: "Type",
                sortable: false,
                ignoreRowClick: true,
                width: "100px",
                cell: (product: Product) => {
                    return <div>
                        {product.productType}
                    </div>
                }
            },
            {
                name: "Unit",
                sortable: false,
                ignoreRowClick: true,
                width: "100px",
                cell: (product: Product) => {
                    return <div>
                        {product.productUnit}
                    </div>
                }
            },
            {
                name: "DisplayPriority",
                sortable: false,
                ignoreRowClick: true,
                width: "100px",
                cell: (product: Product) => {
                    return <div>
                        {product.displayPriority}
                    </div>
                }
            },
            {
                name: "Category",
                sortable: false,
                ignoreRowClick: true,
                width: "150px",
                cell: (product: Product) => {
                    if (product.productCategory === null) {
                        return <div>
                            --
                        </div>
                    } else {
                        const category = this.props.findCategory(product.productCategory)
                        return <div>
                            {category?.category}
                        </div>
                    }
                }
            },
            {
                name: "Operation",
                sortable: false,
                ignoreRowClick: true,
                cell: (product: Product) => {
                    return (
                        <div>
                            {product.source === "VOT" && <div>
                                <button
                                    className={"btn btn-danger"}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        this.handleRemove.bind(this)(product.id);
                                        return false;
                                    }}
                                >
                                    Delete
                                </button>
                                |
                                <button className={"btn btn-danger"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();

                                            this.handleSingleMerge.bind(this)(product.id);

                                            return false;
                                        }}
                                >
                                    Merge into other product
                                </button>
                            </div>}
                            {product.source !== "VOT" && <div>
                                <p>Product created by {product.source},</p>
                                <p>thus cannot be deleted or merged</p>
                            </div>}

                        </div>);
                }
            }
        ];

        const shortColumns = [
            {
                name: "Name",
                selector: "name",
                sortable: true,
                cell: (product: Product) => {
                    return (
                        <div>
                            {product.name}
                            &nbsp;
                            {product.placeholder ? <span
                                title="Placeholder product is created by system automatically. You can update product name to remove this label."
                                className="label label-warning"><i
                                className="fa fa-warning">&nbsp;</i> placeholder</span> : null}
                        </div>
                    )
                }
            }
        ];

        let columns = longColumns;
        if (this.props.isMobile) {
            columns = shortColumns;
        }

        const renderMenuItemChildren = (option: Product, props, index) => {
            return [
                <Highlighter key={"name"} search={props.text}>{option.name}</Highlighter>,
                <div key="productInfo">
                    <InlineProductPhoto product={option}/> {option.name}
                </div>,
            ];
        };

        let allProducts: Array<Product> = [];

        let selectedIds = this.selectedRows.map((product) => {
            return product.id
        });
        this.props.allProducts.forEach((product) => {
            if (selectedIds.indexOf(product.id) < 0) {
                allProducts.push(product);
            }
        });

        return (
            <section className="content">
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Product Settings</title>
                </Helmet>
                <h1>Product Settings</h1>

                <div className="help-block">
                    Product Settings page lists all the items/products (SKU).
                </div>

                <Modal
                    style={{overlay: {zIndex: 1000}}}
                    ariaHideApp={false}
                    isOpen={this.state.mergeModalOpen}
                    contentLabel="Example Modal"
                >
                    <h3>Please select destination product</h3>

                    <form onSubmit={this.handleMergeForm.bind(this)}>
                        <Typeahead id={"typeahead"}
                                   renderMenuItemChildren={renderMenuItemChildren}
                                   selected={this.state.mergeDestination} labelKey="name"
                                   onChange={this.handleMergeDestination.bind(this)} options={allProducts}/>

                        <hr/>
                        <input type={"submit"} className={"btn btn-success"} value="Merge"/>
                        <button onClick={cancel} className={"btn btn-default"}>Cancel</button>
                    </form>
                </Modal>
                <div className={"well"}>
                    <Switch>
                        <Route exact path={"/products/add"}>
                            <AddProductPanel
                                categoriesList={this.state.categories}
                                typesList={this.state.typesList}
                                unitsList={this.state.unitsList}
                                ingredientsList={this.state.ingredientsList}
                                add={this.handleAdd.bind(this)}
                            />
                        </Route>


                        <Route>
                            <div>
                                <Link className={"btn btn-success"} to={"/products/add"}>Add Product</Link>
                                {!this.state.disableStandardProductImporting &&
                                <button onClick={this.importProducts.bind(this)} className="btn btn-danger">Import
                                    Standard Products</button>}
                            </div>
                        </Route>
                    </Switch>
                </div>

                <div className="box box-success">
                    <div className="box-body no-padding">
                        <div className="container-fluid">
                            <div className="form-group">
                                <label>Product Filter</label>
                                <select className="form-control" onChange={this.handleProductFilter.bind(this)}>
                                    <option value="ALL">All Product</option>
                                    <option value="PLACEHOLDER">Placeholder products</option>
                                    <option value="NORMAL">Normal products</option>
                                </select>
                            </div>
                        </div>
                        <DataTable
                            contextActions={contextActions}
                            selectableRows={true}
                            highlightOnHover={true}
                            pointerOnHover={true}
                            defaultSortField={"id"}
                            defaultSortAsc={false}
                            title={"Products"}
                            columns={columns}
                            data={products}
                            clearSelectedRows={this.state.toggledClearRows}
                            onSelectedRowsChange={this.handleTableUpdate.bind(this)}
                            expandableRows
                            expandableRowsComponent={<ProductStatsExp/>}
                        />
                    </div>
                </div>

            </section>
        );
    }
}

const ProductsRaw = withGlobalData(withRouter(withAuthentication(_Products)));


export const Products = (props: Props) => (
    <div>
        <Mobile>
            <ProductsRaw {...props} isMobile={true}/>
        </Mobile>
        <Default>
            <ProductsRaw {...props} isMobile={false} A/>
        </Default>
    </div>
);

