import React from "react";
import {V1MachineDetailResponse} from "./telemetry/vending_machine/v1/typescript-axios";
import {DisplayTime} from "./display_time";
import moment from "moment/moment";

interface Props {
    machineDetail: V1MachineDetailResponse | null
}

export function MachineSignalInfo(props: Props) {
    return (<div className="box box-success well">
            <div className="box-header">
                <h3 className="box-title">Machine LTE Signal Info</h3>
            </div>
            <div className="box-body">
                <table className={"table table-condense"}>
                    <thead>
                    <tr>
                        <th>4G LTE Signal Strength</th>
                        <th>Last Updated At</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{props.machineDetail?.signalStrength ? props.machineDetail?.signalStrength + "%" : "0%"}</td>
                        <td>{props.machineDetail?.lastSignalStrengthUpdatedAt ?
                                <DisplayTime
                                    timestamp={moment(props.machineDetail?.lastSignalStrengthUpdatedAt).unix()}/>
                                :
                                null}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}
