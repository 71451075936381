import React, {Component, Fragment} from "react";
import {DexProduct, Product, ProductMapping, VendingMachine} from "./models";
import {Highlighter, Typeahead} from 'react-bootstrap-typeahead';
import InlineProductPhoto from "./inline_product_photo";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {formatCents, formatMoneyWithoutSymbol} from "./helpers";
import formatUtilPrice from './format_until_price'
import {TelemetryvendingMachinev1MotorCoupling} from "./telemetry/vending_machine/v1/typescript-axios";
import {ManagementServiceApi} from "./api";


interface Props extends IGlobalDataContext {
    mapping: ProductMapping;
    coils: Array<DexProduct>
    vendingMachine: VendingMachine;

    handleRemove(): void;

    updateProductMapping(ProductMapping): Promise<any>;

    updateProductMappingDisplayPriority(ProductMapping): Promise<any>;

    resetProductMappingDisplayPriority(ProductMapping): Promise<any>;

    isMobile: boolean

    handleFix(): void

    motorCouplings: Array<TelemetryvendingMachinev1MotorCoupling>

    allProductMappings: Array<ProductMapping>

    handleRefresh(): void;
}

interface State {
    editing: boolean
    mapping: ProductMapping
    saving: boolean
    display_price: string
    original_price: string
    displayPriority: string
}

class RawProductMapRow extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            editing: false,
            mapping: this.props.mapping,
            saving: false,
            display_price: formatMoneyWithoutSymbol(props.mapping.price, this.props.me.mostRecentCompany.currencyDecimalPoints),
            original_price: formatMoneyWithoutSymbol(props.mapping.original_price, this.props.me.mostRecentCompany.currencyDecimalPoints),
            displayPriority: props.mapping.displayPriority,
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.mapping.price !== state.mapping.price) {
            return {
                display_price: formatMoneyWithoutSymbol(props.mapping.price, props.me.mostRecentCompany.currencyDecimalPoints)
            };
        }
        return null;
    }

    isMissingFromDex(): boolean {
        // if there is no dex config
        if (this.props.coils.length === 0) {
            return false;
        }

        return this.matchedDexProduct() == null;
    }

    matchedDexProduct(): DexProduct | null {
        if (this.props.coils) {
            for (let coil of this.props.coils) {
                if (coil.identifier === this.props.mapping.code) {
                    return coil;
                }
            }
        }

        return null;
    }

    // toggle
    startEditing() {
        if (this.state.editing) {
            this.stopEditing();
        } else {

            this.setState({
                mapping: { ...this.props.mapping },
                editing: true,
                displayPriority: this.props.mapping.displayPriority !== null ? this.props.mapping.displayPriority.toString() : "",
            });
        }
    }

    onProductChange(products: Array<Product>) {
        console.log("New products")
        console.log(products)
        if (products.length > 0) {
            let new_mapping = {...this.state.mapping};
            new_mapping["product_id"] = products[0].id;
            this.setState({mapping: new_mapping});
        }
    }

    stopEditing() {
        this.setState({
            mapping: { ...this.props.mapping },
            editing: false,
            displayPriority: this.props.mapping.displayPriority !== null ? this.props.mapping.displayPriority.toString() : "",
        })
    }

    handleDisplayPriorityChange(e: React.ChangeEvent<HTMLInputElement>){
            if(e.target.value.length > 0){
                let displayPriorityNumber = parseInt(e.target.value)

                this.setState({displayPriority: !isNaN(displayPriorityNumber) ? displayPriorityNumber.toString() : ""});
            }else{
                this.setState({displayPriority: ""});
            }
    }



    save() {
        if (this.props.allProductMappings.filter(mapping => {
            return (mapping.product_id === this.state.mapping.product_id && mapping.price !== this.state.mapping.price);
        }).length > 0) {
            if (!window.confirm('Prices of this product vary. Are you sure to save it?')){
                return;
            }
        }
        if (window.confirm("Save?")) {
            this.setState({saving: true});
            let m = {...this.state.mapping};

            m.displayPriority = this.state.displayPriority?.length ? parseInt(this.state.displayPriority) : null;

            m.price = formatCents(this.state.display_price, this.props.me.mostRecentCompany.currencyDecimalPoints);
            m.original_price = formatCents(this.state.original_price, this.props.me.mostRecentCompany.currencyDecimalPoints);
            this.props.updateProductMapping(m).then((response) => {
                this.stopEditing();
            });
        }
    }

    render() {
        const motorCoupling = this.props.motorCouplings.find((mc) => {
            return parseInt(mc.mainMdbItemNumber ?? "0") === this.props.mapping.mdbRaw
        })


        let options;
        if (!this.props.isMobile) {
            options = ["code", "mdb_code", "capacity", "restock"].map((v, i) => {
                if (this.state.editing) {
                    return (
                        <td key={`${v}-${i}`}>
                            {((v === 'capacity' || v === 'restock') && this.props.findProduct(this.props.mapping.product_id)?.productType === 'COMPOSITE') ? '--' :
                                <input style={{width: "100%"}} type={"text"} value={this.state.mapping[v]}
                                    onChange={(e) => {
                                           let new_mapping = {...this.state.mapping};
                                        new_mapping[v] = e.target.value;
                                           this.setState({mapping: new_mapping});
                                       }}/>}
                            {warning}
                        </td>
                    )
                } else {
                    return (
                        <td key={i}>
                            {((v === 'capacity' || v === 'restock') && this.props.findProduct(this.props.mapping.product_id)?.productType === 'COMPOSITE') ? '--' : this.props.mapping[v]}
                            {(v === 'capacity' && this.props.mapping.cycleCounting) ? '(cycle counting mode)' : null}
                            {warning}
                        </td>);
                }
            });
        } else {
            options = ["capacity", "restock"].map((v, i) => {
                if (this.state.editing) {
                    return (
                        <td key={`${v}-${i}`}>
                            <input style={{width: "100%"}} type={"text"} value={this.state.mapping[v]}
                                onChange={(e) => {
                                       let new_mapping = {...this.state.mapping};
                                    new_mapping[v] = e.target.value;
                                       this.setState({mapping: new_mapping});
                                   }}/> {warning}
                        </td>
                    )
                } else {
                    return (
                        <td key={i}>{this.props.mapping[v]} {(v === 'capacity' && this.props.mapping.cycleCounting) ? '(cycle counting mode)' : null} {warning}</td>);
                }
            });
        }

        const product = this.props.findProduct(this.props.mapping.product_id);

        const removeMotorCoupling = (motorCoupling: TelemetryvendingMachinev1MotorCoupling) => {
            return (e) => {
                e.preventDefault();

                if (window.confirm("Remove motor coupling?")) {
                    console.log("Removing motor coupling");
                    console.log(motorCoupling);

                    this.props.motorCouplings.splice(this.props.motorCouplings.indexOf(motorCoupling), 1);

                    new ManagementServiceApi().machineManagementServiceUpdateMotorCouplings(this.props.vendingMachine.uuid, {
                        motorCouplings: this.props.motorCouplings
                    }).then((resp) => {
                        console.log(resp);

                        this.props.handleRefresh()
                    })
                }
            }
        }

        let nameColumnNormalContent = (<Fragment>
            {product && <InlineProductPhoto product={product}/>}
            {this.props.mapping.product_name}

            &nbsp;
            {this.isMissingFromDex() ? <span className={"text-red"}>Missing from DEX</span> : null}

            <br/>
            {
                motorCoupling &&
                <span className={"text-green"}>Motor Coupling: {motorCoupling.coupledMotorMdbItemNumber}
                    <button onClick={removeMotorCoupling(motorCoupling)}> <i
                        className={"fas fa fa-cogs"}/></button> </span>
            }
        </Fragment>);

        const selectedProduct = this.props.findProduct(this.state.mapping.product_id);
        let selected = selectedProduct ? [selectedProduct] : [];

        const renderMenuItemChildren = (option: Product, props, index) => {
            console.log(option);
            return [
                <Highlighter key={"name"} search={props.text}>{option.name}</Highlighter>,
                <div key="productInfo">
                    <InlineProductPhoto product={option}/> {option.name}
                </div>,
            ];
        };

        const normalProducts = this.props.allProducts.filter((p) => {
            return p.productType !== "INGREDIENT"
        })

        let nameColumnEditingContent = (<Typeahead<Product> id={"typeahead"} options={normalProducts} labelKey="name"
            renderMenuItemChildren={renderMenuItemChildren}
            onChange={this.onProductChange.bind(this)}
                                                            defaultSelected={selected}/>);

        let nameColumn = (<td key={`name-column-${this.props.mapping.id}`}>
            {this.state.editing ? nameColumnEditingContent : nameColumnNormalContent}
        </td>);

        options.unshift(nameColumn);

        let matched = this.matchedDexProduct();
        let warning: any = null;
        if (matched == null || this.props.mapping.price === matched.price || matched.price == null) {
            warning = null;
        } else {
            warning = (<span
                className={"text-red"}>[DEX: {formatUtilPrice(matched.price, this.props.me.mostRecentCompany.currencyDecimalPoints)}]</span>);
        }

        if (this.state.editing) {
            options.push(
                (<>
                    <td key={`editing_key`}>
                        <input style={{width: "100%"}} type={"text"} value={this.state.display_price} onChange={(e) => {
                            this.setState({display_price: e.target.value});
                        }}/> {warning}
                    </td>
                    <td key={`editing_original_price`}>
                        <input style={{width: "100%"}}
                               type={"text"}
                               value={this.state.original_price}
                               onChange={(e) => {
                                   this.setState({original_price: e.target.value});
                               }}/>
                    </td>
                    <td key={`editing_displayPriority`}>
                        <input style={{width: "100%"}}
                               type={"text"}
                               value={this.state.displayPriority}
                               placeholder="default priority when empty"
                               onChange={this.handleDisplayPriorityChange.bind(this)}/>
                    </td>
                </>)
            );

        } else {
            options.push((<>
                <td key={"price"}>{this.state.display_price} {warning}</td>
                <td
                    style={{color: "#FF9999"}}
                    key={"original_price"}>
                    {parseInt(this.state.original_price) !== 0 ? this.state.original_price : null}
                </td>
                <td key={"displayPriority"}>{this.props.mapping.displayPriority}</td>
            </>));
        }


        return (
            <tr className={this.props.mapping.is_faulty ? 'bg-danger' : ''} key={this.props.mapping.id}>
                {options}

                <td>
                    {this.state.editing ? <div>
                        <button className={"btn btn-default"} style={{'cursor': 'pointer', 'width': '70px'}}
                            onClick={() => {
                                this.stopEditing()
                            }}>Cancel
                        </button>
                        &nbsp;
                        <button className={"btn btn-success"} style={{'cursor': 'pointer', 'width': '70px'}}
                            onClick={() => {
                                console.log('save');
                                this.save();
                            }}>Save
                        </button>
                    </div> : null}
                    {this.state.editing ? null :
                        <Fragment>
                            <button className={"btn btn-default"} title={"Click to edit"} style={{'cursor': 'pointer'}}
                                onClick={() => {
                                    this.startEditing()
                                }}>
                                Edit
                            </button>
                            {this.props.vendingMachine.vendingMachineType !== "FOURPRICE" && <span>&nbsp;|&nbsp;</span>}
                            {this.props.vendingMachine.vendingMachineType !== "FOURPRICE" && <button onClick={() => {
                                this.props.handleRemove()
                            }} className="btn btn-danger">Remove
                            </button>}
                            {this.props.mapping.is_faulty && <span>&nbsp;|&nbsp;</span>}
                            {this.props.mapping.is_faulty && <button onClick={() => {
                                this.props.handleFix()
                            }} className="btn btn-danger">Ack Failure
                            </button>}
                        </Fragment>
                    }
                </td>
            </tr>);
    }
}

const ProductMapRow = withGlobalData(RawProductMapRow);

export default ProductMapRow;
