import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {withGlobalData} from "../contexts/global-data";

class _AddProductPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            shelftimeAlert: null,
            shelftimeNotice: null,
            pictureFile: null,
            pictureUrl: "",
            pictureEncodedContent: "",
            currentIngredient: -1,
            selectedCategory: -1,
            selectedType: 'NORMAL',
            selectedUnit: 'ITEM',
            selectedIngredients: [],
            ingredientOptions: [],
            nutrition: '',
            displayPriority: 0,
        }
    }

    componentDidMount() {

    }

    handleProductName(event) {
        event.preventDefault();
        this.setState({name: event.target.value});
    }

    onSelectFile(e) {
        console.log(e.target.files);
        if (e.target.files && e.target.files.length > 0) {
            console.log(e.target.files[0]);
            this.setState({pictureFile: e.target.files[0]})
        } else {
            this.setState({pictureFile: null})
        }

        const fileReader = new FileReader();
        fileReader.onerror = (e) => {
            window.alert("Failed to read file");
        };
        fileReader.onload = (e) => {
            const pictureContent = fileReader.result;
            this.setState({pictureEncodedContent: window.btoa(pictureContent)})
            // this.state.pictureEncodedContent = window.btoa(pictureContent);
        };
        fileReader.readAsBinaryString(e.target.files[0]);
    }

    handleShelftimeNotice(event) {
        this.setState({shelftimeNotice: event.target.value})
    }

    handleShelftimeAlert(event) {
        this.setState({shelftimeAlert: event.target.value})
    }

    handleCategory(event) {
        this.setState({selectedCategory: event.target.value})
    }

    handleType(event) {
        this.setState({selectedType: event.target.value})
    }

    handleUnit(event) {
        this.setState({selectedUnit: event.target.value})
    }

    handleIngredientChange(event) {
        this.setState({currentIngredient: event.target.value})
    }

    handleNutrition(event) {
        this.setState({nutrition: event.target.value})
    }

    handleDisplayPriorityChange(event) {
        let value = parseFloat(event.target.value) || 0;
        if (value < 0) {
            value = Math.abs(value);
        }
        this.setState({ displayPriority: value });
    }

    addIngredient(event) {
        event.preventDefault();
        let newSelectedIngredients = [...this.state.selectedIngredients]
        if (this.state.currentIngredient !== -1) {
            newSelectedIngredients.push({id: parseInt(this.state.currentIngredient), quantity: 0});

            this.setState({selectedIngredients: newSelectedIngredients}, () => {
                this.updateIngredientOptions()
            })
        }
    }

    removeIngredient(event, ingredientId) {
        event.preventDefault();
        let newSelectedIngredients = [...this.state.selectedIngredients]

        newSelectedIngredients = newSelectedIngredients.filter(function (selectedIngredient) {
            return selectedIngredient.id !== ingredientId;
        });

        this.setState({selectedIngredients: newSelectedIngredients}, () => {
            this.updateIngredientOptions()
        })
    }

    updateIngredientOptions() {
        let _ingredientOptions = this.props.ingredientsList.map((ingredient) => {
            let selectedIngredientsId = this.state.selectedIngredients.map((selectedIngredient) => {
                return selectedIngredient.id
            })
            if (!selectedIngredientsId.includes(ingredient.id)) {
                return <option key={ingredient.id} value={ingredient.id}>{ingredient.name}</option>
            } else {
                return -1;
            }
        })

        let ingredientOptions = _ingredientOptions.filter((option) => {
            return option !== -1
        });

        this.setState({ingredientOptions: ingredientOptions}, () => {
            if (ingredientOptions.length > 0) {
                if (this.state.currentIngredient !== ingredientOptions[0].key) {
                    this.setState({currentIngredient: ingredientOptions[0].key})
                }
            } else {
                this.setState({currentIngredient: -1})
            }
        })
    }

    handleQuantityChange(ingredientId, quantity) {
        let _selectedIngredients = this.state.selectedIngredients
        let index = _selectedIngredients.findIndex((selectedIngredient) => {
            return selectedIngredient.id === ingredientId
        })
        let result = _selectedIngredients[index]
        result.quantity = parseInt(quantity)
        this.setState({selectedIngredients: _selectedIngredients})
    }


    render() {
        let ingredients = this.state.selectedIngredients.map((selectedIngredient) => {
            let row = this.props.ingredientsList.find((ingredient) => {
                return ingredient.id === selectedIngredient.id
            })
            return <tr key={row.id}>
                <td>{row.name}</td>
                <td><input type="text" value={selectedIngredient.quantity} onChange={(e) => {
                    this.handleQuantityChange(row.id, e.target.value)
                }}/> {row.productUnit}</td>
                <td>
                    <button className={"btn btn-danger"} onClick={(e) => {
                        this.removeIngredient(e, row.id)
                    }}>Remove
                    </button>
                </td>
            </tr>
        })
        return (
            <div>
                <form>
                    <div className="form-group">
                        <label>Product Name</label>

                        <input className="form-control" value={this.state.name} placeholder={"Product Name"}
                               onChange={this.handleProductName.bind(this)}/>
                    </div>

                    <div className="form-group">
                        <label>Product Picture (optional)</label>
                        <input type="file" onChange={this.onSelectFile.bind(this)} className={"form-control"}/>
                    </div>

                    <div className="form-group">
                        <label>Shelftime Warning (Notice)</label>
                        <input onChange={this.handleShelftimeNotice.bind(this)} type={"text"}
                               value={this.state.shelftimeNotice ?? ''} className="form-control"/>
                    </div>

                    <div className="form-group">
                        <label>Shelftime Warning (Alert)</label>
                        <input onChange={this.handleShelftimeAlert.bind(this)} type={"text"}
                               value={this.state.shelftimeAlert ?? ''} className="form-control"/>
                    </div>

                    <div className="form-group">
                        <label>Category</label>
                        <select id="category" name="category" className="form-control"
                                onChange={this.handleCategory.bind(this)}>
                            <option value={-1}>No Category</option>
                            {this.props.categoriesList.map((category) => {
                                return <option key={category.id} value={category.id}>{category.category}</option>
                            })}
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Type</label>
                        <select id="productType" name="productType" className="form-control"
                                onChange={this.handleType.bind(this)}>
                            {this.props.typesList.map((type) => {
                                return <option key={type} value={type}>{type}</option>
                            })}
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Unit</label>
                        <select id="productUnit" name="productUnit" className="form-control"
                                onChange={this.handleUnit.bind(this)}>
                            {this.props.unitsList.map((unit) => {
                                return <option key={unit} value={unit}>{unit}</option>
                            })}
                        </select>
                    </div>

                    <div className="form-group">
                        <label>Nutrition</label>
                        <textarea onChange={this.handleNutrition.bind(this)}
                                  value={this.state.nutrition ?? ''} className="form-control" placeholder={"input product nutrition"}/>
                    </div>

                    <div className="form-group">
                        <label>Display Priority (The higher the value, the higher the priority)</label>

                        <input
                            onChange={this.handleDisplayPriorityChange.bind(this)}
                            type="number"
                            min="0"
                            value={this.state.displayPriority || 0}
                            className="form-control"
                        />
                    </div>
                </form>
                {this.state.selectedType === 'COMPOSITE' && <form>
                    <div className="form-group">
                        <label>Ingredient</label>
                        <select id="ingredient" name="ingredient" className="form-control"
                                onChange={this.handleIngredientChange.bind(this)} value={this.state.currentIngredient}>
                            {this.state.ingredientOptions}
                        </select>
                        <button onClick={this.addIngredient.bind(this)}>Add ingredient</button>
                    </div>
                </form>}
                {this.state.selectedType === 'COMPOSITE' && <table className="table table-striped">
                    <thead>
                    <tr>
                        <th scope="col">Ingredient Name</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Operation</th>
                    </tr>
                    </thead>
                    <tbody>
                    {ingredients}
                    </tbody>
                </table>}
                <button className="btn btn-success" onClick={(e) => {
                    e.preventDefault();
                    if (this.state.name.length > 0) {
                        this.props.add(this.state.name,
                            this.state.shelftimeNotice,
                            this.state.shelftimeAlert,
                            this.state.pictureEncodedContent === "" ? null : this.state.pictureEncodedContent,
                            this.state.selectedCategory === -1 ? null : this.state.selectedCategory,
                            this.state.selectedType,
                            this.state.selectedUnit,
                            this.state.selectedIngredients,
                            this.state.nutrition,
                            this.state.displayPriority,
                        )
                    }
                }}>Add product
                </button>
                &nbsp; &nbsp;
                <Link className={"btn btn-danger"} to={"/products"}>Cancel</Link>
            </div>
        );
    }
}

export const AddProductPanel = withGlobalData(_AddProductPanel);
